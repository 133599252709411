import { Box, useMediaQuery } from '@mui/material'
import React, { useCallback, useMemo, useState } from 'react'
import { message, Modal, Input, Form, Button } from 'antd'
import { useRequest } from 'ahooks'
import { APICheckPhone, APIgetDownloadPathsByfileName } from '@/components/Stepper/api'
import { AJiantouxiayizhou } from '@imile/icons'
import ViewPic from '@/components/ViewPic'

export default function VerticalLinearStepper(props) {
  const { datas, gotoPay, showPayInfos, waybillNo } = props
  const isPc = useMediaQuery('@media (min-width: 800px)')
  const [form] = Form.useForm()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [picModalVisible, setPicModalVisible] = useState<boolean>(false)
  const [showImg, setShowImg] = useState<string[]>([])

  const handleClick = useCallback(async (item) => {
    const imgStr = (item.proofs || [])?.join()
    const fileInfoRes = await APIgetDownloadPathsByfileName(imgStr)
    const imgsArr = fileInfoRes?.resultObject?.map((item) => item.fileUrl)
    setShowImg(imgsArr || [])
    setIsModalOpen(true)
  }, [])

  const handleCancel = useCallback(() => {
    setIsModalOpen(false)
    form.resetFields(['phone'])
  }, [form])

  const queryCheckPhone = useRequest(APICheckPhone, {
    manual: true,
    debounceWait: 500,
    onSuccess: async (res) => {
      if (res?.resultObject) {
        setPicModalVisible(true)
        handleCancel()
      } else {
        message.error('The last four digits of the phone number verification failed.')
      }
    },
    onError: (err) => {
      console.log(1, err)
    },
  })

  const onFinish = useCallback(() => {
    const phone = form.getFieldValue('phone')
    const params = {
      phone: `${phone}`,
      waybillNo,
    }
    queryCheckPhone.run(params)
  }, [waybillNo])

  const showPayButton = useMemo(
    () => (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: isPc ? '240px' : '120px',
          height: isPc ? '48px' : '30px',
          background: '#FF7900',
          color: '#fff',
          borderRadius: '4px',
          fontSize: '16px',
          cursor: 'pointer',
          m: '20px 0',
        }}
        onClick={() => {
          if (showPayInfos?.payUrl) window.open(showPayInfos.payUrl)
          else message.warning('The correct url was not returned')
        }}
      >
        <div>{gotoPay}</div>
        <Box
          sx={{
            width: '16px',
            height: '16px',
            ml: '8px',
            lineHeight: '16px',
          }}
        >
          <AJiantouxiayizhou color='write' />
        </Box>
      </Box>
    ),
    [isPc, showPayInfos],
  )

  const modalNode = useMemo(
    () => (
      <Modal
        title='View image'
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose={true}
      >
        <Box
          sx={{
            '.ant-input': {
              width: '100%',
            },
            '.ant-form-item-required::after': {
              content: `""`,
            },
          }}
        >
          <p>Enter last 4 digits of sender/receiver phone number for verification</p>
          <Form
            form={form}
            labelCol={{ span: 1 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ phone: null }}
            onFinish={onFinish}
          >
            <Form.Item
              label=' '
              name='phone'
              rules={[{ required: true, message: 'Last 4 digits' }, {
                pattern: /^[0-9]{4}$/,
                message: 'Last 4 digits',
              } ]}
            >
              <Input placeholder='Last 4 digits' maxLength={4}/>
            </Form.Item>
            <Form.Item wrapperCol={{ span: 24 }}>
              <Button type='primary' htmlType='submit'>
                Verify
              </Button>
            </Form.Item>
          </Form>
        </Box>
      </Modal>
    ),
    [isModalOpen, handleCancel, onFinish],
  )

  const showPodNode = useCallback(
    (item: Record<string, any>) => (
      <Box sx={{ color: '#e6a23c', cursor: 'pointer' }} onClick={() => handleClick(item)}>
        【View POD】
      </Box>
    ),
    [],
  )

  return (
    <Box
      sx={{
        // '.travelDetail': {
        //   mt: ['40px'],
        // },
        ul: {
          paddingInlineStart: ['0'],
          position: 'relative',
        },
        'ul li': {
          display: 'flex',
          minHeight: ['80px'],
          mb: '20px',
          '.date': {
            display: isPc ? 'block' : 'none',
            fontWeight: 600,
            fontSize: ['12px', '16px'],
            color: '#000000',
            lineHeight: '20px',
            width: ['80px', '120px'],
            minWidth: ['80px', '120px'],
          },
          '.time': {
            visibility: isPc ? 'visible' : 'hidden',
            fontSize: isPc ? ['12px'] : '14px',
            color: '#838895',
            lineHeight: ['20px'],
            width: isPc ? ['40px'] : '5px',
            marginInlineStart: ['10px', '20px'],
            marginInlineEnd: ['5px', '20px'],
          },
          '.status-other': {
            flex: 1,
            '.status': {
              fontSize: ['16px', '24px'],
              fontWeight: 600,
              color: 'rgba(0, 47, 249)',
              lineHeight: '20px',
              marginBottom: '6px',
            },
            '.other': {
              fontSize: isPc ? ['12px', '14px'] : '14px',
            },
            '.nostage': {
              mt: '-17px',
            },
          },
          '.dot': {
            position: 'relative',
            width: ['8px'],
            span: {
              width: ['8px'],
              height: ['8px'],
              background: 'rgba(0, 47, 249)',
              borderRadius: '100%',
              margin: ['5px'],
              display: 'block',
            },
            '.line': {
              position: 'absolute',
              top: ['18px'],
              bottom: ['5px'],
              left: ['8px'],
              border: '1px solid #e4e7ee',
              borderLeft: 'none',
            },
          },
        },
      }}
    >
      {showPayInfos?.payStatus === '1' ? (
        showPayButton
      ) : (
        <Box sx={{ width: '100%', height: '1px', marginBottom: isPc ? '24px' : '19px' }}></Box>
      )}
      <div className='travelDetail'>
        <ul>
          {datas.map((item, index) => {
            return (
              <li key={index}>
                {index > 0 &&
                item.time?.split(' ')?.[0] === datas[index - 1].time?.split(' ')?.[0] ? (
                  <div className='date'></div>
                ) : (
                  <div className='date'>{item.time?.split(' ')?.[0] || ''}</div>
                )}

                <div className='dot'>
                  <span></span>
                  <div className='line'></div>
                </div>
                <Box className='time'>{item.time?.split(' ')?.[1]?.substr(0, 5) || ''}</Box>
                {item.trackStageTx ? (
                  <Box className='status-other'>
                    {index > 0 && item.trackStageTx === datas[index - 1].trackStageTx ? null : (
                      <div
                        className='status'
                        style={{ color: index === 0 ? '#095ef7' : '#A6A6B9' }}
                      >
                        {item.trackStageTx}
                      </div>
                    )}
                    <div className='other' style={{ color: index === 0 ? '#151741' : '#A6A6B9' }}>
                      {!isPc && <Box sx={{ color: '#838895', m: '8px 0 2px' }}>{item.time}</Box>}
                      <span>{item?.content?.toString()}</span>
                      {item?.proofs?.length && showPodNode(item)}
                    </div>
                  </Box>
                ) : (
                  <div className='status-other'>
                    <div className='other nostage'>
                      <span>{item?.content?.toString()}</span>
                      {item?.proofs?.length && showPodNode(item)}
                    </div>
                  </div>
                )}
              </li>
            )
          })}
        </ul>
      </div>
      {modalNode}
      <ViewPic
        visible={picModalVisible}
        onCancel={() => {
          setPicModalVisible(false)
        }}
        picArray={showImg}
      />
    </Box>
  )
}
