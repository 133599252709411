import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Carousel, Modal } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { Box } from '@mui/material'

const ViewPic = (props) => {
  const bodyRef = useRef(null)
  const selfRef = useRef(null)
  const imgRef = useRef(null)
  const { visible, onCancel, picArray } = props
  const [imgKey, setImgKey] = useState(0)

  return (
    <>
      <Box
        ref={bodyRef}
        sx={{
          '.ant-modal': {
            width: '733px !important',
          },
        }}
      >
        <Modal
          width='733px'
          visible={visible}
          title='View Picture'
          footer={null}
          onCancel={() => {
            onCancel()
          }}
          getContainer={() => {
            return bodyRef?.current || document.body
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: '460px',
              position: 'relative',
              img: {
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              },
              '.prev, .next': {
                width: '40px',
                height: '40px',
                background: 'rgba(0, 0, 0, 0.5)',
                position: 'absolute',
                top: ' 50%',
                transform: 'translateY(-50%)',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                zIndex: 9,
              },

              '.prev': {
                i: {
                  transform: 'rotate(180deg)',
                },
              },

              '.next': {
                right: 0,
              },

              '.content': {
                width: '300px',
                height: '400px',
                background: '#fff',
                overflow: 'hidden',
              },

              '.jian, .jia': {
                width: '40px',
                height: '40px',
                background: 'rgba(0, 0, 0, 0.5)',
                position: 'absolute',
                top: '0%',
                transform: 'translateY(-50%)',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                zIndex: 9,
              },

              '.jian': {
                left: '44%',
              },

              '.jia': {
                right: '44%',
              },

              '.viewpicture-modal-footer': {
                marginTop: '20px',
                display: 'flex',
                img: {
                  width: '70px !important',
                  height: '40px !important',
                  marginRight: '10px',
                  objectFit: 'contain',
                  border: '2px solid transparent',
                  cursor: 'pointer',
                  '&.active': {
                    border: '2px solid #426eff',
                  },
                },
              },
            }}
          >
            <p
              className='prev'
              onClick={() => {
                if (imgKey > 0) {
                  setImgKey(imgKey - 1)
                  return
                }
                if (imgKey === 0 && picArray.length > 0) {
                  setImgKey(picArray.length - 1)
                  return
                }
              }}
            >
              <LeftOutlined />
            </p>
            <Carousel ref={selfRef} dots={false}>
              {picArray?.map((item, key) => {
                return (
                  key === imgKey && (
                    <div className='content'>
                      <img src={item} key={key} ref={imgRef} />
                    </div>
                  )
                )
              })}
            </Carousel>
            <p
              className='next'
              onClick={() => {
                if (imgKey < picArray.length - 1 && picArray.length) {
                  setImgKey(imgKey + 1)
                  return
                }
                setImgKey(0)
              }}
            >
              <RightOutlined />
            </p>
            <section className='viewpicture-modal-footer' style={{ overflowX: 'auto' }}>
              {picArray?.map((item, key) => (
                <img
                  src={item}
                  key={key}
                  onClick={() => {
                    setImgKey(key)
                  }}
                  className={key === imgKey ? 'active' : ''}
                />
              ))}
            </section>
          </Box>
        </Modal>
      </Box>
    </>
  )
}

export default ViewPic
