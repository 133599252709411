import { Helmet } from '@/components'
import { Fuzhi1, Warning } from '@imile/icons'
import { Box, CircularProgress, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useRequest } from 'ahooks'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Cookies from 'js-cookie'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState, useMemo } from 'react'
import { Footer, Header, SEO } from '../components'
import Stepper from '../components/Stepper'
import Track from '../components/Track'

const copyText = (key) => {
  const input = document.createElement('input')
  input.value = key
  document.body.appendChild(input)
  input.select()
  document.execCommand('copy')
  document.body.removeChild(input)
}

const queryWaybill = async (waybillno) => {
  //TODO
  // const url = `https://test-www.52imile.cn/saastms/mobileWeb/track/query?waybillNo=${waybillno}`
  const url = `/saastms/mobileWeb/track/query?waybillNo=${waybillno}`
  return window
    .fetch(url, {
      method: 'get',
      headers: {
        lang: Cookies.get('EXPRESS_LANG'),
      },
    })
    .then((res) => {
      return res.json()
    })
    .catch((err) => {
      // return {
      //   status: 'success',
      //   resultCode: '',
      //   resultObject: {
      //     waybillNo: '6110521378721',
      //     sendSite: 'Riyadh Center',
      //     dispatchStation: 'Riyadh Station',
      //     trackInfos: [
      //       {
      //         content: 'Your order has been delivered successfully.',
      //         trackStage: 2060,
      //         trackStageTx: '转运',
      //         time: '2021-11-11 20:52:42',
      //       },
      //       {
      //         content: 'Our delivery associate is out for delivery and will reach you shortly.',
      //         trackStage: 1003,
      //         trackStageTx: 'Delivery',
      //         time: '2021-11-11 14:20:02',
      //       },
      //       {
      //         content: 'Your order has arrived at 【Riyadh Station】dispatch facility.',
      //         trackStage: 1003,
      //         trackStageTx: 'Delivery',
      //         time: '2021-11-10 20:33:17',
      //       },
      //       {
      //         content: 'Your order has been shipped from 【Riyadh Center】.',
      //         trackStage: 1003,
      //         trackStageTx: 'Delivery',
      //         time: '2021-11-10 08:58:46',
      //       },
      //       {
      //         content: 'Your order has been received at 【Riyadh Center】.',
      //         trackStage: 1003,
      //         trackStageTx: 'Delivery',
      //         time: '2021-11-10 04:58:04',
      //       },
      //       {
      //         content: 'order submitted, pending to receive.',
      //         trackStage: 1001,
      //         trackStageTx: 'Order Creation',
      //         time: '2021-11-05 04:59:55',
      //       },
      //     ],
      //   },
      //   message: 'Successful operation!',
      //   traceId: 'd0c3553148ee47158e3868c11c75b6dc.10846.16377619979872571',
      //   requestToken: null,
      //   forDevlopMessage: null,
      // }
      return err
    })
}

const getLatestDeliveryDate = async (waybillno) => {
  //TODO
  const url = `/saastms/mobileWeb/getLatestDeliveryDate?waybillNo=${waybillno}`
  return window
    .fetch(url, {
      method: 'get',
      headers: {
        lang: Cookies.get('EXPRESS_LANG'),
      },
    })
    .then((res) => {
      return res.json()
    })
    .catch((err) => {
      // return {
      //   status: 'success',
      //   resultCode: '',
      //   resultObject: '2022-12-15',
      //   message: 'Successful operation!',
      //   traceId: 'f6e63506dfc64c20982e7043e23106fa.176.16715276731090161',
      //   requestToken: null,
      //   forDevlopMessage: null,
      // }
      return err
    })
}

const getPayDetailInfo = async (waybillNo, sourceType) => {
  let baseUrl = 'https://test-bms.52imile.cn'
  const host = window?.location?.host
  if (host === 'www.imile.com') {
    baseUrl = `${window?.location.protocol}//bms.imile.com`
  } else if (host === 'uat-www.imile.com') {
    baseUrl = `${window?.location.protocol}//uat-bms.imile.com`
  }
  const url = `${baseUrl}/waukeen/pay/waybill/queryPayDetailInfoByBillNo?waybillNo=${waybillNo}&source=${sourceType}`
  return window
    .fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', lang: Cookies.get('EXPRESS_LANG') },
    } as any)
    .then((res) => {
      return res.json()
    })
    .catch((err) => {
      return err
    })
}

export default (props) => {
  const {} = useTranslation()
  const [datas, setDatas] = useState({
    trackInfos: [],
    waybillNo: '',
  })
  const [dateData, setDateData] = useState<string>('')
  const [showPayInfos, setShowPayInfos] = useState<Record<string, any>>({})
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const isPc = useMediaQuery('@media (min-width: 800px)')
  const theme = useTheme()
  const { direction } = theme
  const pageLocale = JSON.parse(props.data.locales.edges[0].node.data)
  const seoLocale = JSON.parse(props.data.seoLocale.data)
  const footerLocale = JSON.parse(props.data.footerLocale.data)
  console.log(props?.location)

  const getUrlParam = (name) => {
    const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
    const r = props?.location?.search?.substr(1).match(reg)
    let strValue = ''
    if (r !== null) {
      strValue = unescape(r[2])
    }
    return strValue
  }

  const { data, loading, run } = useRequest(queryWaybill, {
    manual: true,
    debounceInterval: 1000,
    throttleInterval: 1000,
    onSuccess: (res, params) => {
      if (res?.resultObject) {
        setDatas(res.resultObject || [])
      } else if (res?.status === 'success' && !res?.resultObject) {
        enqueueSnackbar('no data')
      } else {
        enqueueSnackbar(res.message || 'error')
      }
    },
  })

  const { data: date, run: getDate } = useRequest(getLatestDeliveryDate, {
    manual: true,
    debounceInterval: 1000,
    throttleInterval: 1000,
    onSuccess: (res, params) => {
      if (res?.resultObject) {
        setDateData(res.resultObject || '')
      } else if (res?.status === 'success' && !res?.resultObject) {
        // enqueueSnackbar('no data')
      } else {
        enqueueSnackbar(res.message || 'error')
      }
    },
  })

  const { run: gueryPayDetailInfo } = useRequest(getPayDetailInfo, {
    manual: true,
    debounceInterval: 1000,
    throttleInterval: 1000,
    onSuccess: (res) => {
      if (res?.resultObject) {
        setShowPayInfos(res.resultObject || {})
      }
    },
  })

  const warnPayInfo = useMemo(
    () => (
      <Box
        sx={{
          ...(isPc
            ? {
                paddingLeft: 'var(--page-px)',
                paddingRight: 'var(--page-px)',
                maxWidth: 'var(--page-width)',
                margin: '40px auto 20px',
              }
            : {
                maxWidth: 'var(--page-width)',
                margin: '0 0 20px 0',
              }),
        }}
      >
        <Box
          sx={{
            ...(isPc
              ? {
                  display: 'flex',
                  justifyContent: 'left',
                  alignItems: 'center',
                  height: '60px',
                  background: '#FFEDE5',
                  border: '1px solid #F6CEBB',
                  borderRadius: '8px',
                  color: '#FF7900',
                  padding: ['', '32px'],
                  'div:nth-of-type(1)': {
                    width: '22px',
                    height: '22px',
                  },
                  'div:nth-of-type(2)': {
                    ml: '12px',
                  },
                }
              : {
                  display: 'flex',
                  justifyContent: 'left',
                  alignItems: 'center',
                  height: '36px',
                  background: '#FFEDE5',
                  border: '1px solid #F6CEBB',
                  color: '#FF7900',
                  padding: '16px',
                  'div:nth-of-type(1)': {
                    width: '18px',
                    height: '18px',
                  },
                  'div:nth-of-type(2)': {
                    ml: '12px',
                    fontSize: '13px',
                  },
                }),
          }}
        >
          <div>
            <Warning fontSize={isPc ? 22 : 18} />
          </div>
          <div>{footerLocale.支付提示}</div>
        </Box>
      </Box>
    ),
    [isPc],
  )

  const inputVal =
    // props?.location?.search?.substring(11) ||
    props?.location?.state?.inputval || getUrlParam('waybillNo') || ''

  const sourceType = getUrlParam('source') || '1'

  useEffect(() => {
    if (inputVal && sourceType) {
      run(inputVal)
      getDate(inputVal)
      gueryPayDetailInfo(inputVal, sourceType)
    }
  }, [inputVal, sourceType])

  useEffect(() => {
    document.querySelector('.banner')?.scrollIntoView({})
  }, [])

  return (
    <>
      <SEO
        title={seoLocale.track.title}
        description={seoLocale.track.description}
        pageLocale={pageLocale}
      />
      <Helmet>
        <meta
          name='keywords'
          content='Track your orders, iMile order tracking, Package tracking with tracking number, Parcel tracking, Track shipments, Real-time shipment tracker'
        />

        <meta property='og:type' content='website' />
        <meta property='og:site_name' content='iMile Delivery' />
        <meta property='og:url' content='https://www.imile.com/track' />
        <meta
          property='og:image'
          content='https://www.imile.com/static/9771c77390ee124e3a2e807524d51673/fd432/banner.webp'
        />
        <meta property='og:title' content='Track your orders with iMile' />
        <meta
          property='og:description'
          content='Track your orders using your order number or AirWay bill. Get notified of the status of your order from pick-up to delivery.'
        />
      </Helmet>
      <Header
        location={props.location}
        headerLocale={JSON.parse(props.data.headerLocale.data)}
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />

      {/* banner*/}
      <Box
        className='banner'
        sx={{
          position: 'relative',
          height: ['50vh', '50vh', '650px', '790px'],
          background: '#095ef7',
          '.bg-img': {
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: ['100vw'],
            height: ['294px', '446px', '435px', '528px', '622px'],
            transform: direction === 'rtl' ? 'scaleX(-1)' : '',
          },
        }}
      >
        {isPc ? (
          <img
            src='https://imile-minio-cdn.imile.com/imile-home/offical-web/track/banner.png'
            className='bg-img'
            alt='About iMile Delivery'
          />
        ) : (
          <img
            src='https://imile-minio-cdn.imile.com/imile-home/offical-web/track/banner_mobile.png'
            className='bg-img'
            alt='About iMile Delivery'
          />
        )}
        {/* {isPc ? (
          <StaticImage
            src='../../static/images/track/banner.png'
            alt='About iMile Delivery'
            objectFit='cover'
            loading='lazy'
            placeholder='blurred'
            className='bg-img'
          />
        ) : (
          <StaticImage
            src='../../static/images/track/banner_mobile.png'
            alt='About iMile Delivery'
            objectFit='cover'
            loading='lazy'
            placeholder='blurred'
            className='bg-img'
          />
        )} */}
        <Box
          sx={{
            position: 'absolute',
            '--top': ['25%', '25%', '41%', '38%'],
            top: 'var(--top)',
            transform: 'translate(0, -40%)',
            left: direction === 'ltr' ? ['16px', '80px'] : 'unset',
            right: direction === 'rtl' ? ['16px', '80px'] : 'unset',
            color: '#fff',
            marginInlineStart: 'auto',
            '.tit': {
              fontSize: 'var(--slogan-tit-font-size)',
              fontWeight: 800,
              maxWidth: [277, 435, 450, 500, 555],
              mb: '10px',
            },
          }}
        >
          {isPc ? <h1 className='tit'>{pageLocale.TrackYourOrder}</h1> : null}
          <Track
            locale={pageLocale}
            inputVal={inputVal}
            homebannersx={{
              position: 'absolute',
              top: ['5px', 'calc(var(--top) + 30px)'],
              justifyContent: ['center', 'start'],
              bgcolor: 'unset',
            }}
            callback={(inputVal) => {
              run(inputVal)
              getDate(inputVal)
              gueryPayDetailInfo(inputVal, sourceType)
            }}
          />
        </Box>
      </Box>

      {showPayInfos?.payStatus === '1' && warnPayInfo}

      <Box
        sx={{
          px: 'var(--page-px)',
          maxWidth: 'var(--page-width)',
          margin: showPayInfos?.payStatus === '1' ? '0 auto' : '40px 0 60px',
        }}
      >
        <Box
          sx={{
            bgcolor: ['', '#f6f7fa'],
            borderRadius: '24px',
            padding: ['', '32px'],
          }}
        >
          {loading && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mt: '20px',
                '.MuiCircularProgress-root': {
                  width: '20px !important',
                  height: '20px !important',
                },
              }}
            >
              <CircularProgress style={{ color: 'rgba(0, 47, 249)' }} />
            </Box>
          )}
          {datas.trackInfos.length && datas.waybillNo ? (
            <Box
              sx={{
                '.message-info': {
                  display: 'flex',
                  fontSize: ['12px', '16px'],
                  color: '#8C90A3',
                  background: '#EDEFF3',
                  lineHeight: isPc ? '50px' : '18px',
                  m: '0 -32px',
                  padding: isPc ? '0 32px' : '8px 32px',
                  '.img-box': {
                    height: '100%',
                  },
                },
              }}
            >
              <Box
                className='card-header'
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  fontSize: [12, 16],
                  fontWeight: 600,
                  color: '#000417',
                  // borderBottom: '1px solid #DFE1ED',
                  paddingBottom: '20px',
                  '.waybill_no': {
                    display: 'flex',
                    alignItems: 'center',
                    flex: 1,
                  },
                  '.estimate_time': {
                    display: 'flex',
                    alignItems: 'center',
                  },
                }}
              >
                <div className='waybill_no'>
                  {footerLocale.waybillNo}: {datas.waybillNo}
                  <Fuzhi1
                    sx={{
                      fontSize: 16,
                      color: '#838895',
                      cursor: 'pointer',
                      margin: '0 5px',
                    }}
                    onClick={() => copyText(datas.waybillNo)}
                  />
                </div>
                {dateData && (
                  <div className='estimate_time'>
                    {footerLocale.预计送达日期}: {dateData}
                  </div>
                )}
              </Box>
              <div className='message-info'>
                <div className='img-box'>
                  {isPc ? (
                    <Box
                      component='img'
                      src='/images/explain_web.svg'
                      sx={{ marginRight: '8px' }}
                    ></Box>
                  ) : (
                    <Box
                      component='img'
                      src='/images/explain_phone.svg'
                      sx={{ width: '12px', marginRight: '6px' }}
                    ></Box>
                  )}
                </div>
                {footerLocale.显示时间时区}
              </div>
            </Box>
          ) : null}
          {datas.trackInfos.length ? (
            <Stepper
              datas={datas.trackInfos}
              gotoPay={footerLocale.去支付}
              showPayInfos={showPayInfos}
              waybillNo={datas.waybillNo}
            />
          ) : (
            <Box
              sx={{
                minHeight: ['50vh', '50vh', '438px'],
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '.img': {
                  height: ['120px', '150px'],
                },
              }}
            >
              <img src='/images/empty.png' className='img' />
            </Box>
          )}
        </Box>
      </Box>
      <Footer
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />
    </>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { ns: { in: ["home"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
        }
      }
    }
    headerLocale: locale(ns: { eq: "header" }, language: { eq: $language }) {
      data
    }
    footerLocale: locale(ns: { eq: "footer" }, language: { eq: $language }) {
      data
    }
    languageLocale: locale(ns: { eq: "language" }, language: { eq: $language }) {
      data
    }
    seoLocale: locale(ns: { eq: "seo" }, language: { eq: $language }) {
      data
    }
  }
`
